<template>
    <section class="about">
        <div class="container">
            <h2 class="section-title about__title"> О компании</h2>
            <div class="about__list">
                <div class="about_item">Компания ООО НП "АВИДА-СМАРТ" специализируется на разработке и производстве
                    электронных
                    устройств в
                    условиях ограничений и санкций с максимальным уклоном в сторону импортозамещения.</div>
                <div class="about__item">Основное направление — разработка ОПО и СПО в среде Linux и разработка встроенных
                    систем,
                    исходя из
                    доступности аппаратных компонентов и пожеланий заказчика.</div>
                <div class="about__item">Кроме того, на предприятии внедрены механизмы безопасной разработки ПО. В жизненный
                    цикл
                    разработки
                    встроены статические и динамические анализаторы, такие как: svace, AFL, valgrind.</div>
                <div class="about__item">Разработка программного и аппаратного обеспечения, соответствующего требованиям
                    регуляторов
                    (ФСБ России,
                    ФСТЭК России, МО РФ).</div>
            </div>
        </div>
    </section>
</template>

<script setup></script>

<style scoped lang="scss">
.about {

    @include mobile {
        padding-top: rem(84);
    }

    @include desktop {
        padding-top: rem(40);
    }

    &__title {
        margin-bottom: rem(40);
        text-align: center;
    }

    &__list {
        @include desktop {
            display: grid;
            gap: 20px;
            grid-template-columns: repeat(2, 50%);
            grid-template-rows: repeat(2, auto);
        }

        @include mobile {
            display: grid;
            grid-template-columns: repeat(1);
            grid-template-rows: repeat(4, auto);
            gap: 10px
        }
    }





    &__item {
        @include main-text;
    }
}
</style>