<template>
    <section class="technologies">
        <div class="container">
            <h3 class="block-title technologies__title">Используемые технологии</h3>
            <Carousel :itemsToShow="10" :wrapAround="true" :transition="500">

                <Slide v-for="img in imgs" key="img">
                    <div><img :src="img"></div>
                </Slide>
            </Carousel>
        </div>
    </section>
</template>

<script setup>

import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css'

const imgs = [
    require('@/assets/svg/java.svg'),
    require("@/assets/svg/pyton.svg"),
    require("@/assets/svg/c-sharp.svg"),
    require("@/assets/svg/c-plus.svg"),
    require("@/assets/svg/dot-net.svg"),
    require("@/assets/svg/qt.svg"),
    require("@/assets/svg/postgres.svg"),
    require("@/assets/svg/mysql.svg"),
    require("@/assets/svg/sql.svg"),
    require("@/assets/svg/oracle.svg"),
    require("@/assets/svg/astra.svg"),
    require("@/assets/svg/win.svg"),
    require("@/assets/svg/ubuntu.svg"),
    require("@/assets/svg/debian.svg")
]

</script>


<style lang="scss" scoped>
.technologies {
    padding-top: rem(80);

    &__title {

        @include mobile {
            margin-bottom: rem(40);
        }

        @include desktop {
            margin-bottom: rem(24);
        }
    }

    &-slider {
        position: relative;
        overflow: hidden;

        &__list {
            display: flex;
        }

        &__slide {
            &:not(:first-child) {
                margin-left: rem(20);
            }
        }

        &_edge {

            &::before,
            &::after {
                position: absolute;
                content: "";
                width: rem(120);
                height: 100%;
                top: 0;
                z-index: 1;
            }

            &_begin {
                &::before {
                    background: linear-gradient(90deg, #fff 50%, rgba(255, 255, 255, 0) 100%);
                    left: 0;
                }
            }

            &_end {
                &::after {
                    background: linear-gradient(-90deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 100%);
                    right: 0;
                }
            }
        }
    }
}
</style>